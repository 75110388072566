import { _Types } from "@reactivated";

export const DEFAULT_CONTEXT: _Types["Context"] = {
    ENVIRONMENT: "localdev",
    CI_COMMIT_REF_SLUG: "local",
    CI_COMMIT_SHA: "local",
    SENTRY_DSN: null,
    GOOGLE_MAPS_API_KEY: "AIzaSyDIQSnxu5NE4tFEfEadB_YoEJ2U1It3ELo",
    GTM_CONTAINER_ID: "",
    ONETRUST_SCRIPT_UUID: "",
    RL_HEADER_SRC:
        "https://img-ecomm-rl-dev.azureedge.net/ecomm-header/integration-manifest.json",
    RL_HEADER_UPDATED_ON: "2024-07-15T18:16:12.5258847+00:00",
    RL_HEADER_FETCHED_ON: "2024-07-15T18:16:12.5258847+00:00",
    RL_HEADER_CSS: [
        "/ResourcePackages/RedLobster/assets/dist-header/css/app.4bfab24b.css",
    ],
    RL_HEADER_JS: [
        "/ResourcePackages/RedLobster/assets/dist-header/js/chunk-vendors.b734eeb9.js",
        "/ResourcePackages/RedLobster/assets/dist-header/js/app.f6743a21.js",
    ],
    STATIC_URL: "/static/",

    features: {
        OLO_NEW_HEADER: true,
    },

    core_assets: {
        favicon: "/static/favicon.ico",
    },
    csrf_token: "foo",

    header: {
        body: {
            _type: "StreamField",
            _variant: "cms.HeaderSettings.body",
            value: [
                {
                    id: "9883564d-c2a0-4cd0-80c0-65438749799b",
                    type: "section",
                    value: {
                        links: [
                            {
                                anchor: "",
                                display_name: "Order Now",
                                link_type: "page",
                                url: "/",
                            },
                            {
                                anchor: "",
                                display_name: "Menu",
                                link_type: "page",
                                url: "/",
                            },
                            {
                                anchor: "",
                                display_name: "Catering",
                                link_type: "page",
                                url: "/",
                            },
                            {
                                anchor: "",
                                display_name: "Specials",
                                link_type: "page",
                                url: "/",
                            },
                            {
                                anchor: "",
                                display_name: "Redwards",
                                link_type: "page",
                                url: "/",
                            },
                            {
                                anchor: "",
                                display_name: "Gift Cards",
                                link_type: "page",
                                url: "/",
                            },
                        ],
                        role: "primary",
                    },
                },
                {
                    id: "aa1fbff6-d80b-4024-b25c-39273d546e29",
                    type: "section",
                    value: {
                        links: [
                            {
                                anchor: "",
                                display_name: "About Us",
                                link_type: "page",
                                url: "/",
                            },
                            {
                                anchor: "",
                                display_name: "Careers",
                                link_type: "page",
                                url: "/",
                            },
                            {
                                anchor: "",
                                display_name: "Company Info",
                                link_type: "page",
                                url: "/",
                            },
                            {
                                anchor: "",
                                display_name: "Gen In Touch",
                                link_type: "page",
                                url: "/",
                            },
                        ],
                        role: "secondary",
                    },
                },
                {
                    id: "83ea4377-644e-4b6d-b1d0-3f4c14fdf251",
                    type: "social_media_row",
                    value: [
                        {
                            icon: "facebook",
                            link: "https://example.com",
                        },
                        {
                            icon: "twitter",
                            link: "https://example.com",
                        },
                        {
                            icon: "instagram",
                            link: "https://example.com",
                        },
                        {
                            icon: "youtube",
                            link: "https://example.com",
                        },
                    ],
                },
            ],
        },
        map_page: {
            id: 0,
            title: "Red Lobster Seafood Restaurants",
            url: "/seafood-restaurants/",
        },
    },

    footer: {
        body: {
            _type: "StreamField",
            _variant: "cms.FooterSettings.body",
            value: [
                {
                    id: "94bd54a9-33cb-4743-9b75-94834c29cdb2",
                    type: "site_nav",
                    value: [
                        {
                            is_collapsable: true,
                            links: [
                                {
                                    id: "7311e3e2-e1d3-42bf-a288-9db9daffde91",
                                    type: "link",
                                    value: {
                                        anchor: "",
                                        display_name: "Seafood with Standards",
                                        link_type: "external",
                                        url: "http://example.com",
                                    },
                                },
                                {
                                    id: "9d071e47-5928-4a74-a50b-9d2388687f42",
                                    type: "link",
                                    value: {
                                        anchor: "",
                                        display_name: "Our Heritage",
                                        link_type: "external",
                                        url: "http://example.com",
                                    },
                                },
                                {
                                    id: "5e195ff3-a0e3-41b3-bbce-8ea86662a876",
                                    type: "link",
                                    value: {
                                        anchor: "",
                                        display_name: "RL in the Community",
                                        link_type: "external",
                                        url: "http://example.com",
                                    },
                                },
                            ],
                            title: "About Red Lobster",
                        },
                        {
                            is_collapsable: true,
                            links: [
                                {
                                    id: "af406e47-7dbc-4627-b3a9-05fa9a259a75",
                                    type: "link",
                                    value: {
                                        anchor: "",
                                        display_name: "Work With Us",
                                        link_type: "external",
                                        url: "http://example.com",
                                    },
                                },
                            ],
                            title: "Careers",
                        },
                        {
                            is_collapsable: true,
                            links: [
                                {
                                    id: "b2fcef25-8687-4e64-ac3d-ff34d884a73e",
                                    type: "link",
                                    value: {
                                        anchor: "",
                                        display_name: "News & Press",
                                        link_type: "external",
                                        url: "https://example.com",
                                    },
                                },
                                {
                                    id: "65c7ea93-020a-4966-a576-48a7a83febe6",
                                    type: "link",
                                    value: {
                                        anchor: "",
                                        display_name: "US Locations",
                                        link_type: "page",
                                        url: "http://localhost:8000/seafood-restaurants/locations/",
                                    },
                                },
                            ],
                            title: "Company Info",
                        },
                        {
                            is_collapsable: true,
                            links: [
                                {
                                    id: "36e65ca9-8657-4945-90cc-69a574849859",
                                    type: "link",
                                    value: {
                                        anchor: "",
                                        display_name: "Contact Us",
                                        link_type: "external",
                                        url: "https://example.com",
                                    },
                                },
                            ],
                            title: "Get in Touch",
                        },
                        {
                            is_collapsable: false,
                            links: [
                                {
                                    id: "521cd89a-98ae-419e-b3ad-526be5371384",
                                    type: "social_media_row",
                                    value: [
                                        {
                                            icon: "facebook",
                                            link: "https://example.com",
                                        },
                                        {
                                            icon: "twitter",
                                            link: "https://example.com",
                                        },
                                        {
                                            icon: "youtube",
                                            link: "https://example.com",
                                        },
                                        {
                                            icon: "linkedin",
                                            link: "https://example.com",
                                        },
                                        {
                                            icon: "instagram",
                                            link: "https://example.com",
                                        },
                                        {
                                            icon: "tiktok",
                                            link: "https://example.com",
                                        },
                                    ],
                                },
                            ],
                            title: "Let's Be Friends",
                        },
                    ],
                },
                {
                    id: "548e79f3-196f-4343-9138-5019f307b035",
                    type: "region_nav",
                    value: {
                        regions: [
                            {
                                id: 3,
                                title: "United States – English",
                                url: "http://localhost:8000/",
                            },
                            {
                                id: 4,
                                title: "Canada – English",
                                url: "http://localhost2:8000/",
                            },
                        ],
                    },
                },
                {
                    id: "f74f8b7d-acb3-405d-974b-8ab2ace88798",
                    type: "legal_text",
                    value: '<p data-block-key="l7xvw">2023 ©Red Lobster Hospitality LLC. All Rights Reserved.<br/> <a href="https://www.redlobster.com/terms-conditions">Terms &amp; Conditions</a> <a href="https://www.redlobster.com/accessibility">Accessibility</a><br/> <a href="https://www.redlobster.com/privacy-policy">Privacy Notice (Updated September 1, 2020) / Your California Privacy Rights</a></p>',
                },
            ],
        },
    },
    i18n: {
        language: {
            code: "en_us",
            vernacular_name: "English",
            translated_name: "English",
            dir: "ltr",
        },
        catalog: [],
        formats: [
            {
                key: "DATE_FORMAT",
                value: "N j, Y",
            },
            {
                key: "DATETIME_FORMAT",
                value: "N j, Y, P",
            },
            {
                key: "TIME_FORMAT",
                value: "P",
            },
            {
                key: "YEAR_MONTH_FORMAT",
                value: "F Y",
            },
            {
                key: "MONTH_DAY_FORMAT",
                value: "F j",
            },
            {
                key: "SHORT_DATE_FORMAT",
                value: "m/d/Y",
            },
            {
                key: "SHORT_DATETIME_FORMAT",
                value: "m/d/Y P",
            },
            {
                key: "FIRST_DAY_OF_WEEK",
                value: "0",
            },
            {
                key: "DECIMAL_SEPARATOR",
                value: ".",
            },
            {
                key: "THOUSAND_SEPARATOR",
                value: ",",
            },
            {
                key: "NUMBER_GROUPING",
                value: "3",
            },
            {
                key: "DATE_INPUT_FORMATS",
                value: "['%Y-%m-%d', '%m/%d/%Y', '%m/%d/%y', '%b %d %Y', '%b %d, %Y', '%d %b %Y', '%d %b, %Y', '%B %d %Y', '%B %d, %Y', '%d %B %Y', '%d %B, %Y']",
            },
            {
                key: "TIME_INPUT_FORMATS",
                value: "['%H:%M:%S', '%H:%M:%S.%f', '%H:%M']",
            },
            {
                key: "DATETIME_INPUT_FORMATS",
                value: "['%Y-%m-%d %H:%M:%S', '%Y-%m-%d %H:%M:%S.%f', '%Y-%m-%d %H:%M', '%m/%d/%Y %H:%M:%S', '%m/%d/%Y %H:%M:%S.%f', '%m/%d/%Y %H:%M', '%m/%d/%y %H:%M:%S', '%m/%d/%y %H:%M:%S.%f', '%m/%d/%y %H:%M', '%Y-%m-%d']",
            },
        ],
    },
    messages: [],
    request: {
        csp_nonce: null,
        path: "/blocks/footer/",
        url: "http://localhost:8000/blocks/footer/?debug",
    },
    template_name: "FooterTemplate",
};
