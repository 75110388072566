import React from "react";

import { MediaGridBlockBackgroundVideo as Value } from "@reactivated";

import { VideoChooserBlock } from "@thelabnyc/thelabui/src/components/VideoChooserBlock";

import { useCurrentBreakpoint } from "../../hooks/layout";
import { Fineprint } from "./Fineprint";
import { TileContainer } from "./TileContainer";

interface Props {
    value: Value;
    numSiblings: number;
}

export const BackgroundVideoTile = (props: Props) => {
    const viewport = useCurrentBreakpoint();
    const video = viewport.belowMedium
        ? props.value.mobile_video
        : props.value.desktop_video;
    const videoWidth = viewport.belowMedium
        ? props.value.mobile_file_width
        : props.value.desktop_file_width;
    const videoHeight = viewport.belowMedium
        ? props.value.mobile_file_height
        : props.value.desktop_file_height;
    if (!video || !videoWidth || !videoHeight) {
        return null;
    }
    return (
        <TileContainer
            aspectRatio={videoWidth / videoHeight}
            numSiblings={props.numSiblings}
        >
            <a href={props.value.link?.url || undefined}>
                <VideoChooserBlock
                    iOSFriendlyMutedAutoPlay={true}
                    attrs={{
                        loop: true,
                        controls: false,
                        disablePictureInPicture: true,
                        title: video.title,
                    }}
                    value={video}
                />
            </a>
            {props.value.fineprint && props.value.fineprint_width && (
                <Fineprint
                    fineprint={props.value.fineprint}
                    fineprint_dropshadow={props.value.fineprint_dropshadow}
                    fineprint_width={props.value.fineprint_width}
                    text_style={props.value.text_style}
                />
            )}
        </TileContainer>
    );
};
