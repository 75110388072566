import React from "react";
import { t } from "ttag";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Location, Weekday, WeekdayInt, check } from "../../utils/models";

import styles from "./TodaysHours.module.scss";

const getCurrentWeekday = (): Weekday => {
    const now = new Date();
    const day = now.getDay();
    return check(WeekdayInt.decode(day));
};

export const TodaysHours = ({
    label = t`Today's Hours:`,
    hoursType = "hours",
    ...props
}: {
    location: Location;
    className?: string;
    label?: string;
    hoursType?: "hours" | "curbsideHours" | "deliveryHours";
}) => {
    const today = getCurrentWeekday();
    const hours = props.location[hoursType].find((p) => p.dayOfWeek === today);
    if (!hours) {
        return null;
    }
    let [isClosed, closedText] = [
        props.location.isTemporarilyClosed,
        t`Temporarily Closed`,
    ];
    switch (hoursType) {
        case "curbsideHours": {
            [isClosed, closedText] = [
                props.location.hasCurbside,
                t`Not Available`,
            ];
            break;
        }
        case "deliveryHours": {
            [isClosed, closedText] = [
                props.location.hasDelivery,
                t`Not Available`,
            ];
            break;
        }
    }

    return (
        <p className={concatClassNames([props.className, styles.hours])}>
            {label}
            &nbsp;
            {isClosed ? (
                <>{closedText}</>
            ) : (
                <>
                    <time>{hours.open}</time>
                    &nbsp;&ndash;&nbsp;
                    <time>{hours.close}</time>
                </>
            )}
        </p>
    );
};
