import { VisuallyHidden } from "@react-aria/visually-hidden";
import React, { useId, useState } from "react";
import { t } from "ttag";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";

import { useUserLocation } from "../../../hooks/location";
import { listLocations } from "../../../utils/api";
import { geocode } from "../../../utils/maps";
import {
    LatLng,
    LocationProximity,
    fromGoogleLatLng,
    isoMiles,
} from "../../../utils/models";
import { Svg } from "../../Svg";

import styles from "./SearchForm.module.scss";

const QUERY_RADIUS = isoMiles.wrap(200);
const QUERY_LIMIT = 4;

const geocodeText = async (searchText: string): Promise<LatLng | null> => {
    const results = await geocode({
        address: searchText,
    });
    const result = results[0];
    if (!result?.geometry?.location) {
        console.error("Result has no geometry info");
        return null;
    }
    return fromGoogleLatLng(result.geometry.location);
};

const searchLocations = async (
    coords: LatLng,
): Promise<LocationProximity[]> => {
    const resp = await listLocations({
        center: coords,
        radius: QUERY_RADIUS,
        limit: QUERY_LIMIT,
    });
    return resp.locations;
};

export const SearchForm = (props: {
    setResults: (locations: LocationProximity[]) => void;
    mapPageURL: string | null;
}) => {
    const searchID = useId();
    const [searchText, setSearchText] = useState("");
    const { userLocation } = useUserLocation();

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const coords = await geocodeText(searchText);
        const locations = coords ? await searchLocations(coords) : [];
        props.setResults(locations);
    };

    const onUseDetectedLocation = async (
        e: React.MouseEvent<HTMLButtonElement>,
    ) => {
        e.preventDefault();
        const locations = userLocation.detected
            ? await searchLocations(userLocation.detected)
            : [];
        props.setResults(locations);
    };

    return (
        <div className={styles.container}>
            <p className={styles.cta}>
                {t`Tell us where you are for the`}
                <br />
                {t`latest specials and prices`}
            </p>
            <form className={styles.form} onSubmit={onSubmit}>
                <label className={styles.label} htmlFor={searchID}>
                    <Svg name="magnifying-glass" />
                    <VisuallyHidden>{t`Search`}</VisuallyHidden>
                </label>
                <input
                    className={styles.searchInput}
                    id={searchID}
                    required={true}
                    type="search"
                    aria-label={t`Search by city, state, or zip`}
                    placeholder={t`Search by city, state, or zip`}
                    value={searchText}
                    onChange={(e) => {
                        setSearchText(e.currentTarget.value);
                    }}
                />
                <Clickable type="submit" className={styles.submit}>
                    <Svg name="caret-right" />
                </Clickable>
            </form>
            <div className={styles.otherActions}>
                <Clickable
                    className={styles.currentLocationButton}
                    onClick={onUseDetectedLocation}
                >{t`Use My Current Location`}</Clickable>
                {props.mapPageURL && (
                    <Clickable href={props.mapPageURL}>{t`View Map`}</Clickable>
                )}
            </div>
        </div>
    );
};
