import React, { useContext } from "react";
import { t } from "ttag";

import { Context } from "@reactivated";

import { RLID, isoRLID } from "../../utils/models";

import styles from "./index.module.scss";

export const OrderNowButton = (props: {
    countryRootURL: string | null;
    rlid: RLID | number;
    hasOnlineOrdering: boolean;
}) => {
    const context = useContext(Context);
    const rlid = RLID.is(props.rlid) ? isoRLID.unwrap(props.rlid) : props.rlid;
    if (!props.hasOnlineOrdering) {
        return null;
    }
    const countryRootURL = props.countryRootURL || context.request.url;
    const orderURL = new URL(`/order?rlid=${rlid}`, countryRootURL).href;
    return (
        <a className={styles.button} href={orderURL}>
            {t`Order Now`}
        </a>
    );
};
