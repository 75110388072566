import React from "react";
import { t } from "ttag";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";

import { Location, LocationProximity, isoRLID } from "../../../utils/models";
import { SearchResult } from "./SearchResult";

import styles from "./SearchResults.module.scss";

export const SearchResults = (props: {
    locations: LocationProximity[];
    mapPageURL: string | null;
    onSelect: (location: Location) => void;
}) => {
    return (
        <div>
            <div className={styles.results}>
                {props.locations.map(({ location, distance }) => (
                    <SearchResult
                        key={isoRLID.unwrap(location.rlid).toString()}
                        location={location}
                        distance={distance}
                        onSelect={props.onSelect}
                    />
                ))}
            </div>
            {props.mapPageURL && (
                <p className={styles.viewAll}>
                    <Clickable
                        href={props.mapPageURL}
                    >{t`View All Locations`}</Clickable>
                </p>
            )}
        </div>
    );
};
