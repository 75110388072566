import React from "react";
import { Helmet } from "react-helmet-async";

import { Context } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { FooterBlock } from "../FooterBlock";
import { HeaderBlock } from "../HeaderBlock";
import { PageHeader } from "../PageHeader";

import styles from "./index.module.scss";

interface Props {
    title: string;
    seo_title?: string;
    search_description?: string;
    robots?: string;
    rootClassName?: string;
    mainClassName?: string;
    children?: React.ReactNode;
}

export const PageSkeleton = (props: Props) => {
    const context = React.useContext(Context);
    return (
        <>
            <Helmet>
                <html lang="en" />
                <meta charSet="utf-8" />
                <title>{props.seo_title || props.title}</title>
                {props.search_description && (
                    <meta
                        name="description"
                        content={props.search_description}
                    />
                )}
                {props.robots && <meta name="robots" content={props.robots} />}
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <meta
                    property="og:image"
                    content="/ecomm/images/togo-logo.png"
                />
                <link
                    rel="icon"
                    sizes="16x16 32x32 64x64"
                    href={context.core_assets.favicon}
                    type="image/x-icon"
                />

                {/* Begin OneTrust Cookies Consent Notice */}
                <script
                    src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                    type="text/javascript"
                    data-domain-script={context.ONETRUST_SCRIPT_UUID}
                ></script>
                <script type="text/javascript">{`
                    function OptanonWrapper() {}
                `}</script>
                {/* End OneTrust Cookies Consent Notice */}

                <script
                    src={`${context.STATIC_URL}js/footer.js`}
                    type="text/javascript"
                    defer
                />
                <script
                    type="text/javascript"
                    src={`https://maps.googleapis.com/maps/api/js?key=${context.GOOGLE_MAPS_API_KEY}&amp;libraries=places,geometry`}
                ></script>

                {/* GTM */}
                <script type="text/javascript">{`
                    dataLayer = [];
                    (function(w, d, s, l, i) {
                        w[l] = w[l] || [];
                        w[l].push({
                            'gtm.start':
                            new Date().getTime(),
                            event: 'gtm.js'
                        });
                        var f = d.getElementsByTagName(s)[0],
                            j = d.createElement(s),
                            dl = l != 'dataLayer' ? '&l=' + l : '';
                        j.async = true;
                        j.src =
                        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                        f.parentNode.insertBefore(j, f);
                    })(window, document, 'script', 'dataLayer', '${context.GTM_CONTAINER_ID}');
                `}</script>
            </Helmet>
            {context.features.OLO_NEW_HEADER && context.header?.body.value ? (
                <HeaderBlock
                    value={context.header.body.value}
                    mapPageURL={context.header.map_page?.url ?? null}
                />
            ) : (
                <PageHeader />
            )}
            <div
                className={concatClassNames([
                    styles.container,
                    props.rootClassName,
                ])}
            >
                {props.children && (
                    <main id="mainContent" className={props.mainClassName}>
                        {props.children}
                    </main>
                )}
            </div>
            {context.footer?.body.value && (
                <FooterBlock value={context.footer.body.value} />
            )}
        </>
    );
};
