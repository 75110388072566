import * as Dialog from "@radix-ui/react-dialog";
import React from "react";
import { t } from "ttag";

import {
    HeaderBlock as HeaderBlockValue,
    NavSection as NavSectionValue,
    SocialMediaIconList as SocialMediaIconListValue,
} from "@reactivated";

import { SocialMediaIconList } from "@thelabnyc/thelabui/src/components/SocialMediaIcon";
import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";
import { classNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Svg } from "../Svg";

import styles from "./MobileNav.module.scss";

const LinkSection = (props: { value: NavSectionValue }) => (
    <div
        className={classNames([
            [styles.primaryNav, props.value.role === "primary"],
            [styles.secondaryNav, props.value.role === "secondary"],
        ])}
    >
        {props.value.links?.map((link, i) =>
            link.url ? (
                <a key={i} href={link.url}>
                    {link.display_name}
                </a>
            ) : null,
        )}
    </div>
);

const SocialMediaRow = (props: { value: SocialMediaIconListValue }) => (
    <SocialMediaIconList
        className={styles.socialMediaRow}
        value={props.value}
    />
);

export const MobileNav = (props: { value: HeaderBlockValue }) => (
    <>
        <Dialog.Root>
            <Dialog.Trigger asChild>
                <button className={styles.burger}>
                    <Svg name="burger" />
                </button>
            </Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Content className={styles.content}>
                    <Dialog.Close asChild>
                        <button className={styles.burger}>
                            <Svg name="close" />
                        </button>
                    </Dialog.Close>
                    <a
                        className={styles.loginLink}
                        href="/account/login?returnUrl=/"
                    >
                        {t`Log In or Create an Account`}
                    </a>
                    <StreamField
                        tagName={React.Fragment}
                        components={{
                            section: LinkSection,
                            social_media_row: SocialMediaRow,
                        }}
                        value={props.value}
                    />
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    </>
);
