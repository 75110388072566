import React from "react";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";

import styles from "./index.module.scss";

type Props = IntrinsicElementProps<HTMLDivElement>;

export const PageContentContainer = (props: Props) => (
    <div
        {...props}
        className={concatClassNames([
            styles.pageContentContainer,
            props.className,
        ])}
    >
        {props.children}
    </div>
);
