import React from "react";

import {
    AlignedRichTextBlock as AlignedRichTextBlockValue,
    BooleanBlockOrNull,
    MediaGridBlockColumnWidth,
} from "@reactivated";

import { classNames } from "@thelabnyc/thelabui/src/utils/styles";

import { AlignedRichTextBlock } from "../AlignedRichTextBlock";

import styles from "./Fineprint.module.scss";

interface IProps {
    fineprint: AlignedRichTextBlockValue;
    fineprint_dropshadow?: BooleanBlockOrNull;
    fineprint_width: MediaGridBlockColumnWidth;
    text_style: null | ("light" | "dark");
    className?: string;
}

export const Fineprint = (props: IProps) => {
    const fineprintPercentage = `${
        (parseInt(props.fineprint_width) / 12) * 100
    }%`;

    return (
        <div
            className={classNames([
                [props.className, props.className != undefined],
                [styles.fineprintContainer, true],
                [styles.fineprintTextLight, props.text_style === "light"],
                [
                    styles.fineprintDropshadow,
                    props.fineprint_dropshadow
                        ? props.fineprint_dropshadow
                        : false,
                ],
            ])}
            style={{ width: fineprintPercentage }}
        >
            <AlignedRichTextBlock
                className={styles.fineprint}
                value={props.fineprint}
            />
        </div>
    );
};
