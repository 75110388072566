import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import React from "react";

import {
    HeaderBlock as HeaderBlockValue,
    NavSection as NavSectionValue,
} from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import styles from "./DesktopNav.module.scss";

const LinkSection = (props: { value: NavSectionValue }) => (
    <>
        {props.value.role === "primary" &&
            props.value.links?.map((link, i) =>
                link.url ? (
                    <NavigationMenu.Item key={i}>
                        <NavigationMenu.Link
                            className={styles.link}
                            href={link.url}
                        >
                            {link.display_name}
                        </NavigationMenu.Link>
                    </NavigationMenu.Item>
                ) : null,
            )}
    </>
);

export const DesktopNav = (props: { value: HeaderBlockValue }) => (
    <NavigationMenu.Root>
        <NavigationMenu.List className={styles.list}>
            <StreamField
                tagName={React.Fragment}
                components={{
                    section: LinkSection,
                    social_media_row: () => null,
                }}
                value={props.value}
            />
        </NavigationMenu.List>
    </NavigationMenu.Root>
);
