import { Svg } from "@client/components/Svg";
import React from "react";
import { t } from "ttag";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";

import { Location } from "../../../utils/models";
import { GetDirectionsLink } from "../../LocatorMap/GetDirectionsLink";
import { PhoneNumber } from "../../LocatorMap/PhoneNumber";
import { TodaysHours } from "../../LocatorMap/TodaysHours";
import { MakeReservationButton } from "../../MakeReservationButton";
import { OrderNowButton } from "../../OrderNowButton";

import styles from "./Details.module.scss";

export const Details = (props: {
    location: Location;
    onChangeLocation: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) => (
    <div className={styles.container}>
        <div>
            <h2 className={styles.title}>
                {props.location.address1}
                <br />
                {props.location.city}, {props.location.state}{" "}
                {props.location.zip}
            </h2>
            <GetDirectionsLink location={props.location} />
            {props.location.localPageURL && (
                <Clickable
                    href={`/seafood-restaurants/locations/${props.location.localPageURL}`}
                >
                    {t`View Local Page`}
                </Clickable>
            )}
            <Clickable
                className={styles.changeLocationLink}
                onClick={props.onChangeLocation}
            >
                <Svg name="caret-left" />
                {t`Change Location`}
            </Clickable>
        </div>
        <div>
            <TodaysHours
                className={styles.today}
                location={props.location}
                label={t`Today's Hours`}
                hoursType="hours"
            />
            <TodaysHours
                className={styles.options}
                location={props.location}
                label={t`Delivery`}
                hoursType="deliveryHours"
            />
            <TodaysHours
                className={styles.options}
                location={props.location}
                label={t`Curbside`}
                hoursType="curbsideHours"
            />
        </div>
        <PhoneNumber phoneNumber={props.location.phone} />
        <div>
            <MakeReservationButton location={props.location} />
            <OrderNowButton
                countryRootURL={props.location.countryRootURL}
                rlid={props.location.rlid}
                hasOnlineOrdering={props.location.hasOnlineOrdering}
            />
        </div>
    </div>
);
