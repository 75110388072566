import React from "react";
import { t } from "ttag";

import { HeaderBlock as HeaderBlockValue } from "@reactivated";

import { useCurrentBreakpoint } from "../../hooks/layout";
import { Svg } from "../Svg";
import { DesktopNav } from "./DesktopNav";
import { LocationChooserPopover } from "./LocationChooserPopover";
import { MobileNav } from "./MobileNav";

import styles from "./index.module.scss";

export const HeaderBlock = (props: {
    value: HeaderBlockValue;
    mapPageURL: string | null;
}) => {
    const viewport = useCurrentBreakpoint();
    return (
        <header className={styles.header}>
            <div className={styles.mainNavBar}>
                <div className={styles.navSection}>
                    {viewport.belowMedium && <MobileNav {...props} />}
                    <a href="/" className={styles.logo}>
                        <Svg name="logo" />
                    </a>
                    {!viewport.belowMedium && <DesktopNav {...props} />}
                </div>
                <div className={styles.navSection}>
                    <a
                        className={styles.accountLink}
                        href="/account/login?returnUrl=/"
                    >
                        <Svg name="account" />
                        <span>{t`Log In`}</span>
                    </a>
                </div>
            </div>
            <LocationChooserPopover mapPageURL={props.mapPageURL} />
        </header>
    );
};
