import { Json } from "io-ts-types";
import Cookies from "js-cookie";

import {
    AnyObjOrArrayOrNull,
    LocalStorageBackend,
    Repository,
    newPersistentObjContext,
} from "@thelabnyc/thelabui/src/hooks/persistent-state";

import { Location, isoRLID, nullable } from "../utils/models";

const COOKIE_NAME_RLID = "li";
const STORAGE_KEY_RLID = "storage.rl-us.rlid";
const STORAGE_KEY_LOCATIONOBJ = "storage.rl-us.locationObj";

/**
 * Same as LocalStorageBackend, but also writes the RLID to a separate key in
 * localStorage as well as a cookie. It does this only for backwards
 * compatibility with other RL systems. We never actually read back these extra
 * values.
 */
class PreferredLocationStorageBackend<
    Value extends AnyObjOrArrayOrNull,
    EncodedValue extends Json,
> extends LocalStorageBackend<Value, EncodedValue> {
    public async write(obj: Value): Promise<void> {
        if (Location.is(obj)) {
            const unwrappedRLID = isoRLID.unwrap(obj.rlid).toString();
            localStorage.setItem(STORAGE_KEY_RLID, unwrappedRLID);
            Cookies.set(COOKIE_NAME_RLID, unwrappedRLID, { expires: 365 });
        } else {
            localStorage.removeItem(STORAGE_KEY_RLID);
            Cookies.remove(COOKIE_NAME_RLID);
        }
        await super.write(obj);
    }
}

const repo = new Repository({
    backend: new PreferredLocationStorageBackend({
        codec: nullable(Location),
        defaultVal: null,
        storageKey: STORAGE_KEY_LOCATIONOBJ,
    }),
    writeLockName: STORAGE_KEY_LOCATIONOBJ,
    broadcastChannelName: STORAGE_KEY_LOCATIONOBJ,
});

export const {
    Context: PreferredLocationContext,
    Provider: PreferredLocationProvider,
} = newPersistentObjContext({
    repo: repo,
});
