import * as Popover from "@radix-ui/react-popover";
import React, { useContext, useState } from "react";
import { t } from "ttag";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";

import { Svg } from "../../../components/Svg";
import { PreferredLocationContext } from "../../../hooks/preferred-location";
import { Location, LocationProximity } from "../../../utils/models";
import { Details } from "./Details";
import { PopoverTrigger } from "./PopoverTrigger";
import { SearchForm } from "./SearchForm";
import { SearchResults } from "./SearchResults";

import styles from "./Popover.module.scss";

type ViewState = "ShowPreferredLocation" | "ShowSearchForm";

export const LocationChooserPopover = (props: {
    mapPageURL: string | null;
}) => {
    const preferredLocation = useContext(PreferredLocationContext);
    const [viewState, setViewState] = useState<ViewState>(
        "ShowPreferredLocation",
    );
    const [searchResults, setSearchResults] = useState<LocationProximity[]>([]);

    const onSelectLocation = (location: Location) => {
        preferredLocation.set(location);
        setViewState("ShowPreferredLocation");
    };

    const onChangeLocation = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setViewState("ShowSearchForm");
    };

    return (
        <Popover.Root>
            <PopoverTrigger location={preferredLocation.value} />
            <Popover.Portal>
                <Popover.Content className={styles.popoverContent}>
                    {viewState === "ShowPreferredLocation" &&
                    preferredLocation.value ? (
                        <Details
                            location={preferredLocation.value}
                            onChangeLocation={onChangeLocation}
                        />
                    ) : (
                        <>
                            <SearchForm
                                setResults={setSearchResults}
                                mapPageURL={props.mapPageURL}
                            />
                            {searchResults.length > 0 && (
                                <SearchResults
                                    locations={searchResults}
                                    mapPageURL={props.mapPageURL}
                                    onSelect={onSelectLocation}
                                />
                            )}
                        </>
                    )}
                    <Popover.Close asChild>
                        <Clickable className={styles.close}>
                            {t`Hide location details`}
                            <Svg name="caret-up" />
                        </Clickable>
                    </Popover.Close>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};
