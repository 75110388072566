import { Svg } from "@client/components/Svg";
import React from "react";
import { t } from "ttag";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";

import { Location, Miles, isoMiles } from "../../../utils/models";

import styles from "./SearchResult.module.scss";

export const SearchResult = (props: {
    location: Location;
    distance: Miles;
    onSelect: (location: Location) => void;
}) => (
    <Clickable
        className={styles.container}
        onClick={() => {
            props.onSelect(props.location);
        }}
    >
        <h2 className={styles.title}>{props.location.address1}</h2>
        <p>
            {props.location.city},&nbsp;
            {props.location.state}&nbsp;
            {props.location.zip}
        </p>
        <p className={styles.mileage}>
            {isoMiles.unwrap(props.distance).toFixed(1)} mi
        </p>
        {props.location.hasDelivery && (
            <p className={styles.availableOptions}>
                <Svg name="delivery" />
                {t`Delivery Available`}
            </p>
        )}
        {props.location.isDiningRoomOpened && (
            <p
                className={styles.availableOptions}
            >{t`Indoor Dining Available`}</p>
        )}
    </Clickable>
);
