import React from "react";

import { MediaGridBlockImage as Value } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import { useCurrentBreakpoint } from "../../hooks/layout";
import { Fineprint } from "./Fineprint";
import { TileContainer } from "./TileContainer";

interface Props {
    value: Value;
    numSiblings: number;
}

export const ImageTile = (props: Props) => {
    const viewport = useCurrentBreakpoint();
    const altText = props.value.alt_text ?? undefined;
    const image = viewport.belowMedium
        ? props.value.mobile_image
        : props.value.desktop_image;
    if (!image) {
        return null;
    }
    return (
        <TileContainer
            aspectRatio={image.width / image.height}
            numSiblings={props.numSiblings}
        >
            <a href={props.value.link?.url || undefined}>
                <ImageChooserBlock
                    value={image}
                    imageAttrs={{
                        alt: altText,
                        title: image.title,
                    }}
                />
            </a>
            {props.value.fineprint && props.value.fineprint_width && (
                <Fineprint
                    fineprint={props.value.fineprint}
                    fineprint_dropshadow={props.value.fineprint_dropshadow}
                    fineprint_width={props.value.fineprint_width}
                    text_style={props.value.text_style}
                />
            )}
        </TileContainer>
    );
};
